import styled from 'styled-components';
import media from 'styled-media-query';
import BLUE_LINE from '../../assets/img/blueLineImg.svg';
import GREEN_LINE from '../../assets/img/greenLineImg.svg';

export const CustomContent = styled.div`
  position: relative;
  div {
    z-index: 2;
  }

  :after {
    content: '';
    background-image: url(${BLUE_LINE});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 36%;
    left: 0%;
    width: 99vw;
    height: 650px;
    z-index: -3;
  }
  :before {
    content: '';
    background-image: url(${GREEN_LINE});
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    top: 35%;
    left: -2%;
    width: 99vw;
    height: 650px;
    z-index: -5;
  }

  ${media.lessThan('1921px')`
    :after{
      top: 30%;
    }
    :before{
      top: 25%;
    }
  `}
  ${media.lessThan('1681px')`
    :after{
      width: 94vw;
      top: 27%;
    }
    :before{
      top: 25%;
    }
  `}
  ${media.lessThan('1681px')`
    :after{
      width: 84vw;
      top: 26%;
    }
    :before{
      top: 25%;
    }
  `}
  ${media.lessThan('1367px')`
    :after{
      width: 98vw;
      top: 16%;
    }
    :before{
      top: 18%;
    }
  `}
  ${media.lessThan('1281px')`
    :after{
      top: 15%;
    }
    :before{
      top: 17%;
    }
  `}
  ${media.lessThan('1025px')`
    :after{
      display: none;
    }
    :before{
      display: none;
    }
  `}
`;
