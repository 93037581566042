import * as React from 'react';
import GRAPHIC_ONE from '../../assets/img/graphicOneImg.svg';
import GRAPHIC_TWO from '../../assets/img/graphicTwoImg.svg';
import HOME_DASH from '../../assets/img/homeImg.webp';
import WeDash from '../../assets/img/logo.svg';
import WhiteWeDash from '../../assets/img/white_logo.svg';
import WOMAN_IPAD from '../../assets/img/womanIpadImg.webp';
import {
  AboutSession,
  CtaFooterSession,
  Footer,
  Header,
  IntegrationSession,
  KnowHowSession,
  KnowSession,
  MakeSession,
  OptimizeSession,
  PlansSession,
  WeAreSession,
} from '../../components';
import {
  footerSocialProp,
  headerOptions,
  integrationToolsProp,
  knowSessionDifferentialsProps,
  plansForPlansSessionProps,
} from './mockProps';
import * as S from './styled';

const IndexPage = () => {
  return (
    <>
      <Header
        logo={WeDash}
        navLinks={headerOptions}
        navButton="https://app.wedash.digital/"
      />
      <S.CustomContent>
        <OptimizeSession
          title="Otimize seu tempo!"
          text="Dashboards de marketing, vendas e growth em um só lugar. Conheça a tecnologia que permite visualizar dados, obter insights e tomar decisões mais assertivas nos negócios."
          imgSrc={HOME_DASH}
          imgAlt="Imagem formado por um avatar mostrando a foto de um homem branco, de cabelos castanhos curto, olhos claros sorrindo, juntamente a uma tabela com dados para filtros detalhados de perído, origem, mídia, e abaixo a demonstração desses filtros ou como escrito na imagem, funil de vendas, demonstrando todos os leads, sessões/cliques, e oportunidades, todas demonstrando em linhas especificas o seu nome e valor."
          btnText="quero ser we"
          btnTextColor="secondary"
          btnTextColorHover="secondary"
          btnBackgroundColor="primary"
          btnBackgroundColorHover="primary"
          btnBorderColor="primary"
          btnBorderColorHover="primary"
          redirect="/lead-form"
        />
        <AboutSession
          imgSrc={WOMAN_IPAD}
          imgAlt="Mulher de pele clara, cabelos negros e óculos preto olhando para o ipad ao qual segura e mexe com as duas mãos, este estando localizado em sua diagonal inferior direita, o dispositivo tem uma cor clara e forma quadriculada, com pouca expessura, o dispositivo é fino."
          title="Sobre"
          btnText="quero ser We"
          btnTextColor="secondary"
          btnTextHoverColor="secondary"
          btnBackgroundColor="primary"
          btnBackgroundHoverColor="primary"
          btnBorderColor="primary"
          btnBorderHoverColor="primary"
          redirect="/lead-form"
        >
          Nós nascemos de respostas comuns para 3 perguntas abaixo:
          <li>Quanto tempo você leva para preparar seus relatórios?</li>
          <li>
            Quanto tempo você precisa para ter insights consistentes das
            campanhas de marketing e vendas?
          </li>
          <li>Quanto investimento você já direcionou de forma errada?</li>
          We.Dash é um dashboard com dados integrados do desempenho de marketing
          e vendas dos seus canais digitais para maior performance nos negócios.
        </AboutSession>
      </S.CustomContent>
      <WeAreSession
        titles={['We.', 'AreEverything_']}
        graphics={[GRAPHIC_ONE, GRAPHIC_TWO]}
        btnText="Comece agora"
        btnTextColor="secondary"
        btnTextColorHover="secondary"
        btnBackgroundColor="primary"
        btnBackgroundColorHover="primary"
        btnBorderColor="primary"
        btnBorderColorHover="primary"
        redirect="/lead-form"
      >
        O <strong>We.Dash</strong> mapeia, reúne e traz a visualização dos
        principais indicadores de inbound marketing, CRM, ferramentas de
        automação e analytics.
        <br />
        <br /> Por todas suas características reunidas, o dashboard da Bowe leva
        o nome de
        <strong> We.Dash</strong>. Um dash feito por nós para que sua equipe de
        marketing e vendas possa visualizar todas as informações de forma rápida
        e fácil. <br />
        <br /> Reduza o tempo da sua equipe fazendo relatórios e foque em novos
        projetos e inovação. <br />
        <strong>We.Dash by Bowe</strong>. Todos os dashboards de marketing,
        vendas e growth em um só lugar.
      </WeAreSession>
      <IntegrationSession
        title="Integrações"
        subtitle="Um Dashboard com integração com as principais plataformas do mercado. "
        tools={integrationToolsProp}
        btnText="Quero saber mais"
        btnTextColor="secondary"
        btnTextColorHover="secondary"
        btnBackgroundColor="primary"
        btnBackgroundColorHover="primary"
        btnBorderColor="primary"
        btnBorderColorHover="primary"
        redirect="/lead-form"
      />
      <KnowSession
        title="Diferenciais"
        differentials={knowSessionDifferentialsProps}
        btnText="Quero mais resultados"
        btnTextColor="secondary"
        btnTextColorHover="secondary"
        btnBackgroundColor="primary"
        btnBackgroundColorHover="primary"
        btnBorderColor="primary"
        btnBorderColorHover="primary"
        redirect="/lead-form"
      />
      <KnowHowSession
        title={['Saiba como o ', ' ', 'We.Dash pode te ajudar']}
        list={[
          'Com We.Dash, de forma rápida e fácil, sua empresa tem clareza sobre quais os melhores encaminhamentos para as áreas de marketing e vendas.',
          'Funil de vendas, taxa de conversões por lead qualificado, custos e investimentos por cada canal, tudo em uma tela de fácil entendimento para tomar decisões assertivas.',
        ]}
        btnText="Vem ser We"
        btnTextColor="secondary"
        btnTextHoverColor="secondary"
        btnBackgroundColor="primary"
        btnBackgroundHoverColor="primary"
        btnBorderColor="primary"
        btnBorderHoverColor="primary"
        redirect="/lead-form"
      />
      <MakeSession
        textColor="secondary"
        title="We.Dash"
        subtitle={[
          'Um dashboard desenvolvido para mostrar',
          'o melhor caminho para o crescimento.',
        ]}
        mobileSubtitle="Um dashboard feito para mostrar o melhor caminho para você crescer."
        btnText="Comece agora"
        btnTextColor="third"
        btnTextHoverColor="third"
        btnBackgroundColor="white"
        btnBackgroundHoverColor="white"
        btnBorderColor="white"
        btnBorderHoverColor="white"
        redirect="/lead-form"
      />

      <PlansSession
        title="Planos"
        subtitles={[
          'Otimize seu tempo e direcione suas tomadas de decisões.',
          ' Escolha o melhor plano para você e comece agora.',
        ]}
        plans={plansForPlansSessionProps}
        plansPadding="100px 0 0 0"
      />
      <CtaFooterSession
        title={['We', '.', 'Dash by Bowe']}
        subtitles={[
          'Todos os dashboards de marketing,',
          'vendas e growth em um só lugar.',
        ]}
        btnText="Comece agora"
        btnTextColor="secondary"
        btnTextHoverColor="secondary"
        btnBorderColor="primary"
        btnBorderHoverColor="primary"
        btnBackgroundColor="primary"
        btnBackgroundHoverColor="primary"
        fullWidth
        redirect="/lead-form"
      />
      <Footer
        social={footerSocialProp}
        logoSrc={WhiteWeDash}
        logoAlt="Logo da empresa em duas linhas alinhadas a esquerda, na primeira linha está escrito we.dash e na segunda linha está escrito by bowe"
        logoScroll="header"
      />
    </>
  );
};

export default IndexPage;
